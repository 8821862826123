import React, { useEffect, useState } from "react";
import { Ranking } from "../interfaces";
import Arrow from "../assets/Arrow.svg";
import Plus from "../assets/Plus.svg";
import LightPlus from "../assets/LightPlus.svg";
import LightArrow from "../assets/LightArrow.svg";
import { Link, useParams } from "react-router-dom";
import * as utils from "../utils";
import Navbar from "./Navbar";
import SearchFilter from "./SearchFilter";
import { getBoard } from "../lib/controllers/board.controller";
import Tooltip from "./Tooltip";
import "../styles/Rankings.css";
import { SupabaseClient } from "@supabase/supabase-js";
import { useDarkMode } from "./DarkModeContext";

interface RankingsProps {
	supabaseClient: any;
}

const Rankings: React.FC<RankingsProps> = ({ supabaseClient }) => {
	const [boardName, setBoardName] = useState<string>("");
	const [rankings, setRankings] = useState<Ranking[]>([]);
	const [filteredRankings, setFilteredRankings] = useState<Ranking[]>([]);
	const [hoveredRanking, setHoveredRanking] = useState<Ranking | null>(null);
	const [hoveredLineColor, setHoveredLineColor] = useState<string>("");
	const { darkMode } = useDarkMode();
	const colors = [
		"#EE3F23",
		"#819DA3",
		"#BBA8D5",
		"#02ADEF",
		"#05AC4E",
		"#EBBC02",
		"#F1EDE2",
		"#CDDEF2",
	];

	const { uuid } = useParams();
	useEffect(() => {
		getBoard(supabaseClient, uuid!)
			.then((data) => {
				setBoardName(data.name);
				setRankings(data.entry);
				setFilteredRankings(data.entry);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	const filterData = (searchTerm: string) => {
		if (searchTerm === "") {
			setFilteredRankings(rankings);
		} else {
			const filteredData = rankings.filter((data) =>
				data.name.toLowerCase().includes(searchTerm.toLowerCase())
			);
			setFilteredRankings(filteredData);
		}
	};

	const handleRankingHover = (index: number, ranking: Ranking) => {
		setHoveredRanking(ranking);
		const colorIndex = Math.floor(index / 3) % colors.length;
		setHoveredLineColor(colors[colorIndex]);
	};

	const handleRankingLeave = () => {
		setHoveredRanking(null);
		setHoveredLineColor("");
	};

	return (
		<>
			<Navbar currentPage="board" uuid={uuid} name={boardName} />
			<div className="board-name">
				<Tooltip text="Continue ranking">
					<Link to="/">
						<img
							src={darkMode ? LightArrow : Arrow}
							alt="arrow"
							style={{ transform: "rotate(225deg)", padding: "30px 0px" }}
						/>
					</Link>
				</Tooltip>
			</div>
			<div className="rankings">
				<div className="ranking-list">
					<SearchFilter filterData={filterData} />
					<a
						href="https://6z538a947mr.typeform.com/to/giLFd70H"
						className="create-ranking-button"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={darkMode ? LightPlus : Plus} alt="plus button" />
					</a>

					{filteredRankings.map((ranking, index) => (
						<a
							key={index}
							href={ranking.url}
							rel="noreferrer"
							target="_blank"
							className={`ranking-item ${
								hoveredRanking === ranking ? "active" : ""
							}`}
							onMouseEnter={() => handleRankingHover(index, ranking)}
							onMouseLeave={handleRankingLeave}
						>
							<div
								className="hover-line"
								style={{ backgroundColor: hoveredLineColor }}
							/>
							{ranking.rank}.{"  "}
							{ranking.name}
						</a>
					))}
				</div>
				<div className="ranking-preview">
					{hoveredRanking && (
						<div className="ranking-details">
							<img
								src={utils.fixImage(hoveredRanking.images[0])}
								alt={hoveredRanking.name}
							/>
							<div>
								<p>
									<span>Win/Loss</span>{" "}
									<span>
										<b>
											{hoveredRanking.wins}:
											{hoveredRanking.votes - hoveredRanking.wins}
										</b>
									</span>
								</p>
								<p>
									<span>Win streak</span>{" "}
									<span>
										<b>0</b>
									</span>
								</p>
								<p>
									<span>ELO rating</span>{" "}
									<span>
										<b>{hoveredRanking.elo}</b>
									</span>
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Rankings;
