import React from "react";
import "../styles/SearchFilter.css";

interface SearchFilterProps {
	filterData: (searchTerm: string) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({ filterData }) => {
	return (
		<div className="search-filter">
			<input
				className="filter"
				placeholder="Search"
				onChange={(e) => filterData(e.target.value)}
			/>
		</div>
	);
};

export default SearchFilter;
