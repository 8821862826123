import React, { useState, useEffect, useCallback } from "react";
import Arrow from "../assets/Arrow.svg";
import LightArrow from "../assets/LightArrow.svg";
import { Ranking } from "../interfaces";
import Navbar from "./Navbar";
import Tooltip from "./Tooltip";
import { Link } from "react-router-dom";
import "../styles/UpdateRankings.css";
import { fixImage } from "../utils";
import { getH2H } from "../lib/controllers/board.controller";
import { updateEntry } from "../lib/controllers/entry.controller";
import { SupabaseClient } from "@supabase/supabase-js";
import { useDarkMode } from "./DarkModeContext";

interface H2HProps {
	supabaseClient: SupabaseClient;
}

interface Comparison {
	a: Ranking;
	b: Ranking;
}

const UpdateRankings: React.FC<H2HProps> = ({ supabaseClient }) => {
	const [comparison, setComparison] = useState<Comparison | undefined>();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [expandedSide, setExpandedSide] = useState<"left" | "right" | null>(
		null
	);
	const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
	const [slideDirection, setSlideDirection] = useState<"next" | "prev" | null>(
		null
	);
	const [votedSide, setVotedSide] = useState<"a" | "b" | null>(null);

	const uuid: string = "136670bd-7a34-4ba8-85ae-be46a2f17a23";
	const name: string = "Website";
	const { darkMode } = useDarkMode();

	useEffect(() => {
		getH2H(supabaseClient, uuid)
			.then((data: Comparison) => {
				setComparison(data);
			})
			.catch((e: Error) => {
				console.log(e);
			});
	}, [supabaseClient, uuid]);

	const handleImageClick = (side: "left" | "right"): void => {
		setExpandedSide(side);
		setIsExpanded(true);
		setCurrentImageIndex(0);
	};

	const handleClose = useCallback((): void => {
		setIsExpanded(false);
		setExpandedSide(null);
		setCurrentImageIndex(0);
		setSlideDirection(null);
	}, []);

	const handleSelection = (selected: "a" | "b"): void => {
		if (comparison) {
			setVotedSide(selected);
			updateEntry(supabaseClient, comparison.a, comparison.b, selected)
				.then((response: any) => {
					setTimeout(() => {
						setVotedSide(null);
						getH2H(supabaseClient, uuid)
							.then((data: Comparison) => setComparison(data))
							.catch((e: Error) => console.log(e));
					}, 1000);
				})
				.catch((error: Error) => {
					console.error("Error updating rankings:", error);
					setVotedSide(null);
				});
		}
	};

	const getImages = useCallback(() => {
		if (comparison && expandedSide) {
			return expandedSide === "right"
				? comparison.b.images
				: comparison.a.images;
		}
		return [];
	}, [comparison, expandedSide]);

	const handleNextImage = (): void => {
		const images = getImages();
		if (images.length > 1) {
			setSlideDirection("next");
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
		}
	};

	const handlePrevImage = (): void => {
		const images = getImages();
		if (images.length > 1) {
			setSlideDirection("prev");
			setCurrentImageIndex(
				(prevIndex) => (prevIndex - 1 + images.length) % images.length
			);
		}
	};

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.key === "Escape" && isExpanded) {
				handleClose();
			}
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [isExpanded, handleClose]);

	return (
		<>
			<Navbar currentPage="board" uuid={uuid} name={name} />
			<div className="update">
				<div className="board-name">
					<Link to={`/rankings/${uuid}`}>
						<Tooltip text="Go to Leaderboard">
							<img
								src={darkMode ? LightArrow : Arrow}
								alt="arrow"
								style={{ transform: "rotate(45deg)", padding: "30px 0px" }}
							/>
						</Tooltip>
					</Link>
				</div>
				{comparison && (
					<div className="ranking">
						<div className="choices">
							<div className={`choice ${votedSide === "a" ? "voted" : ""}`}>
								<img
									className={`display ${votedSide === "a" ? "voted" : ""}`}
									src={fixImage(comparison.a.images[0])}
									alt="left choice"
									onClick={() => handleImageClick("left")}
								/>
								<label>
									<Tooltip text="Vote Here">
										<button
											className="vote-button"
											onClick={() => handleSelection("a")}
										>
											{comparison.a.name}
										</button>
									</Tooltip>
									<a
										href={comparison.a.url}
										target="_blank"
										rel="noreferrer noopener"
									>
										<img src={darkMode ? LightArrow : Arrow} alt="arrow" />
									</a>
								</label>
							</div>
							<div className={`choice ${votedSide === "b" ? "voted" : ""}`}>
								<img
									className={`display ${votedSide === "b" ? "voted" : ""}`}
									src={fixImage(comparison.b.images[0])}
									alt="right choice"
									onClick={() => handleImageClick("right")}
								/>
								<label>
									<Tooltip text="Vote Here">
										<button onClick={() => handleSelection("b")}>
											{comparison.b.name}
										</button>
									</Tooltip>
									<a
										href={comparison.b.url}
										target="_blank"
										rel="noreferrer noopener"
									>
										<img src={darkMode ? LightArrow : Arrow} alt="arrow" />
									</a>
								</label>
							</div>
						</div>
					</div>
				)}
			</div>
			{isExpanded && comparison && expandedSide && (
				<div className="modal active" onClick={handleClose}>
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div className="modal-image-container">
							{getImages().map((image, index) => {
								let className = "modal-image";
								if (index === currentImageIndex) {
									className += " active";
								} else if (
									index ===
									(currentImageIndex - 1 + getImages().length) %
										getImages().length
								) {
									className +=
										slideDirection === "next" ? " prev" : " transition-prev";
								} else if (
									index ===
									(currentImageIndex + 1) % getImages().length
								) {
									className +=
										slideDirection === "prev" ? " next" : " transition-next";
								}
								return (
									<img
										key={index}
										src={fixImage(image)}
										alt={`expanded view ${index + 1}`}
										className={className}
									/>
								);
							})}
						</div>
						{getImages().length > 1 && (
							<div className="modal-arrows">
								<img
									src={LightArrow}
									alt="previous"
									className="modal-arrow left-arrow"
									onClick={handlePrevImage}
								/>
								<img
									src={LightArrow}
									alt="next"
									className="modal-arrow right-arrow"
									onClick={handleNextImage}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default UpdateRankings;
