import { SupabaseClient } from "@supabase/supabase-js";

function calculateKFactor(elo: number): number {
	const highEloThreshold = 2400;
	const mediumEloThreshold = 1600;
	const highEloKFactor = 16;
	const mediumEloKFactor = 32;
	const lowEloKFactor = 64;

	if (elo >= highEloThreshold) {
		return highEloKFactor;
	} else if (elo >= mediumEloThreshold) {
		return mediumEloKFactor;
	} else {
		return lowEloKFactor;
	}
}

async function getEntry(
  supabaseClient: SupabaseClient,
  uuid: string
) {
  const { data, error } = await supabaseClient
    .from("entry")
    .select("*")
    .eq("uuid", uuid)
    .single();
  if (error) throw new Error(error.message);
  return data;
}

async function createEntry(
  supabaseClient: SupabaseClient,
  name: string, url: string, image: string, board_uuid: string
) {
  const { data, error } = await supabaseClient
    .from("entry")
    .insert([{ name: name, url: url, image: image, board_uuid: board_uuid }]);
  if (error) throw new Error(error.message);
  return data;
}

async function updateEntry(
  supabaseClient: SupabaseClient,
  a: any, b: any, w: string
) {
  const winner = w === "a" ? a : b;
  const loser = w === "a" ? b : a;
  if (!winner || !loser) throw new Error("Invalid input");
  const winnerKFactor = calculateKFactor(winner.elo);
  const loserKFactor = calculateKFactor(loser.elo);
  const winnerExpectedScore = 1 / (1 + Math.pow(10, (loser.elo - winner.elo) / 400));
  const loserExpectedScore = 1 / (1 + Math.pow(10, (winner.elo - loser.elo) / 400));
  winner.elo += winnerKFactor * (1 - winnerExpectedScore);
  winner.elo = Math.round(winner.elo);
  winner.wins += 1;
  winner.votes += 1;
  loser.elo += loserKFactor * (0 - loserExpectedScore);
  loser.elo = Math.round(loser.elo);
  loser.votes += 1;
  const winnerUpdate = await supabaseClient
    .from("entry")
    .update({ elo: winner.elo, wins: winner.wins, votes: winner.votes })
    .eq("name", winner.name);
  const loserUpdate = await supabaseClient
    .from("entry")
    .update({ elo: loser.elo, votes: loser.votes })
    .eq("name", loser.name);
  if (winnerUpdate.error || loserUpdate.error) throw new Error("Unexpected error with updating entries");
  return "Successfully updated"
}
export { getEntry, createEntry, updateEntry };