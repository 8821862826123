import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
	Boards,
	CreateBoard,
	CreateRanking,
	Rankings,
	UpdateRankings,
} from "./components/index";
import { createClient } from "@supabase/supabase-js";
import { DarkModeProvider } from "./components/DarkModeContext";
import "./App.css";

const supabase = createClient(
	process.env.REACT_APP_SUPABASE_URL!,
	process.env.REACT_APP_SUPABASE_ANON_KEY!
);

const App: React.FC = () => {
	return (
		<DarkModeProvider>
			<Router>
				<div className="App">
					<Routes>
						<Route
							path="/rankings/:uuid"
							element={<Rankings supabaseClient={supabase} />}
						/>
						<Route
							path="/"
							element={<UpdateRankings supabaseClient={supabase} />}
						/>
						<Route
							path="/rank/create/:uuid/:name"
							element={<CreateRanking />}
						/>
					</Routes>
				</div>
			</Router>
		</DarkModeProvider>
	);
};

export default App;
