import React, { useState, useEffect, useRef } from "react";
import "../styles/Tooltip.css";

interface TooltipProps {
	text: string;
	children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const tooltipRef = useRef<HTMLDivElement>(null);

	const handleMouseEnter = () => {
		setIsVisible(true);
	};

	const handleMouseLeave = () => {
		setIsVisible(false);
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		setPosition({ x: e.clientX, y: e.clientY });
	};

	useEffect(() => {
		if (isVisible && tooltipRef.current) {
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			const x = position.x - tooltipRect.width / 2;
			const y = position.y - tooltipRect.height - 10;

			tooltipRef.current.style.left = `${x}px`;
			tooltipRef.current.style.top = `${y}px`;
		}
	}, [isVisible, position]);

	return (
		<div
			className="tooltip-container"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseMove={handleMouseMove}
		>
			{children}
			{isVisible && (
				<div ref={tooltipRef} className="tooltip" style={{ position: "fixed" }}>
					{text}
				</div>
			)}
		</div>
	);
};

export default Tooltip;
