import React, { useRef, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import ReCAPTCHA from "react-google-recaptcha";
import "../styles/CreateRanking.css";
import Arrow from "../assets/Arrow.svg";

const CreateRanking: React.FC = () => {
	const [rankingName, setRankingName] = useState<string>("");
	const [url, setUrl] = useState<string>("");
	const [image, setImage] = useState<string>("");
	const { uuid, name } = useParams();
	const recaptchaRef = useRef<ReCAPTCHA>(null);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const token = process.env.REACT_APP_TOKEN;
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		const data = {
			name: rankingName,
			url: url,
			image: image,
			board_uuid: uuid,
		};
		const recaptchaValue = recaptchaRef?.current?.getValue();

		axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/entry`, data, config)
			.then()
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<Navbar currentPage="board" uuid={uuid} name={name} />
			<div className="create">
				<div className="back-button">
					<Link to={`/rankings/${uuid}`}>
						<img src={Arrow} alt="arrow" />
						<span>Back</span>
					</Link>
				</div>
				<b>Add to {name}</b>
				<form onSubmit={handleSubmit}>
					<div className="field">
						<label>Name:</label>
						<input
							type="text"
							value={rankingName}
							onChange={(e) => setRankingName(e.target.value)}
							required
						/>
					</div>
					<div className="field">
						<label>URL:</label>
						<input
							type="text"
							value={url}
							onChange={(e) => setUrl(e.target.value)}
							required
						/>
					</div>
					<div className="field">
						<label>Image:</label>
						<input
							type="text"
							value={image}
							onChange={(e) => setImage(e.target.value)}
							required
						/>
					</div>
					<ReCAPTCHA
						ref={recaptchaRef}
						size="invisible"
						sitekey={
							process.env.REACT_APP_CAPTCHA_SECRET
								? process.env.REACT_APP_CAPTCHA_SECRET
								: ""
						}
					/>
					<div className="field">
						<button type="submit">Create</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default CreateRanking;
