import React from "react";
import { Link } from "react-router-dom";
import DarkModeToggle from "./DarkModeToggle";
import "../App.css";

interface NavbarProps {
	currentPage: string;
	uuid: string | undefined;
	name: string | undefined;
}

const Navbar: React.FC<NavbarProps> = (state) => {
	return (
		<div className="header">
			<div className="nav-content">
				<h1>
					<b>The Leaderboard Company</b>
				</h1>
				<DarkModeToggle />
			</div>
			<hr />
			{/* <div className="links">
				{state.currentPage === "board" && state.uuid ? (
					<>
						<Link to={`/rank/${state.uuid}/${state.name}`}>H2H</Link>
					</>
				) : null}
			</div> */}
		</div>
	);
};

export default Navbar;
