import React from "react";
import { useDarkMode } from "./DarkModeContext";
import "../styles/DarkModeToggle.css";

const DarkModeToggle: React.FC = () => {
	const { darkMode, toggleDarkMode } = useDarkMode();

	return (
		<div className="dark-mode-toggle">
			<input
				type="checkbox"
				id="dark-mode-toggle"
				checked={darkMode}
				onChange={toggleDarkMode}
			/>
			<label htmlFor="dark-mode-toggle">
				<span className="toggle-track">
					<span className="toggle-indicator"></span>
				</span>
			</label>
		</div>
	);
};

export default DarkModeToggle;
