import { SupabaseClient } from "@supabase/supabase-js";
// import * as BoardTypes from "../schema/board.schema.ts";

const NUMBER_BOARDS_PER_PAGE = 12;
const NUMBER_ENTRY_PER_BOARD = 3;

async function getBoards(supabaseClient: any, page: number, entry: boolean) {
	let query = supabaseClient
		.from("board")
		.select(entry ? "*, entry(*)" : "uuid")
		.range(page * NUMBER_BOARDS_PER_PAGE, (page + 1) * NUMBER_BOARDS_PER_PAGE);
	if (entry) {
		query = query
			.limit(NUMBER_ENTRY_PER_BOARD, { foreignTable: "entry" })
			.order("elo", { ascending: false, foreignTable: "entry" });
	}
	const { data, error } = await query;
	if (error) throw new Error(error?.message);
	return data;
}

async function getBoard(supabaseClient: SupabaseClient, uuid: string) {
	const { data, error } = await supabaseClient
		.from("board")
		.select("*, entry (*)")
		.eq("uuid", uuid)
		.order("elo", { ascending: false, foreignTable: "entry" })
		.single();
	if (error || data === null) throw new Error(error?.message);
	data.entry.forEach((e: any, idx: number) => {
		e.rank = idx + 1;
	});
	return data;
}

async function getH2H(supabaseClient: SupabaseClient, uuid: string) {
	const { data, error } = await supabaseClient.rpc("h2h", { buid: uuid });
	if (error) throw new Error(error.message);
	return {
		a: data[0],
		b: data[1],
	};
}

async function createBoard(supabaseClient: SupabaseClient, name: string) {
	const { data, error } = await supabaseClient
		.from("board")
		.insert([{ name: name }]);
	if (error) throw new Error(error.message);
	return data;
}

export { getBoards, getBoard, getH2H, createBoard };
